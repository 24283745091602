import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

export interface ConfirmDialogWithTextConfirmationData {
  message: string;
  confirmText: string;
  action?: string;
  title?: string;
}


@Component({
  selector: 'eurogard-confirm-dialog-with-text-confirmation',
  standalone: true,
  templateUrl: './confirm-dialog-with-text-confirmation.component.html',
  styleUrl: './confirm-dialog-with-text-confirmation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatInput,
    MatFormField,
  ],
})
export class ConfirmDialogWithTextConfirmationComponent {

  protected readonly data: ConfirmDialogWithTextConfirmationData;
  protected readonly defaultTitle = $localize`Confirm`;
  protected readonly defaultAction = $localize`Confirm`;

  protected confirmText = '';

  private readonly snackBar = inject(MatSnackBar)
  private readonly clipboard = inject(Clipboard);


  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfirmDialogWithTextConfirmationData,
  ) {
    this.data = data;
  }

  copyToClipboard() {
    this.clipboard.copy(this.data.confirmText);
    this.snackBar.open($localize`Copied to clipboard`, $localize`Close`, {duration: 2000})
  }

}
