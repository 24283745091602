import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findByValue',
  standalone: true,
})
export class FindByValuePipe implements PipeTransform {

  transform(array: Array<{ value: any, [key: string]: any }>, value: any, key: string): any {
    const foundElement = array.find(item => item.value === value);
    return foundElement ? foundElement[key] : null;
  }

}
