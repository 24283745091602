import { ChangeDetectionStrategy, Component, Input, computed, inject, signal } from '@angular/core';
import { DataSourceDirective } from '@rxap/data-source/directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SubscribedMachinesPanelDataSource } from './subscribed-machines-panel.data-source';
import {
  PriorityItem,
  PrioritySortingControlComponent,
} from 'angular-controls/priority-sorting-control/priority-sorting-control.component';
import { AutocompleteItem } from 'angular-forms/autocomplete-form/types';
import {
  NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsRemoteMethod,
} from 'open-api/remote-methods/notification-accordion-subscribed-machines-controller-update-subscriptions.remote-method';
import { AUTOCOMPLETE_ITEM_AUTOCOMPLETE_OPTIONS_METHOD } from 'angular-forms/autocomplete-form/tokens';
import { SubscribedMachinesAutocompleteMethod } from './subscribed-machines-autocomplete.method';
import { FormsModule } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  UserSettingsAccordionDataSource
} from 'angular-components/layout/default-header/user-profile-icon/user-settings/user-settings-accordion.data-source';
import { ACCORDION_DATA_SOURCE } from '@rxap/data-source/accordion';
import { NgIf } from '@angular/common';
import { DataSourceErrorComponent } from '@rxap/data-source';

export interface AutocompleteMethod extends AutocompleteItem {
  name: string;
}

@Component({
  standalone: true,
  selector: 'eurogard-subscribed-machines-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './subscribed-machines-panel.component.html',
  styleUrls: [ './subscribed-machines-panel.component.scss' ],
  imports: [
    DataSourceDirective,
    MatProgressBarModule,
    PrioritySortingControlComponent,
    FormsModule,
    NgIf,
    DataSourceErrorComponent,
  ],
  providers: [
    SubscribedMachinesPanelDataSource,
    UserSettingsAccordionDataSource,
    {
      provide: AUTOCOMPLETE_ITEM_AUTOCOMPLETE_OPTIONS_METHOD,
      useClass: SubscribedMachinesAutocompleteMethod,
    },
  ],
})
export class SubscribedMachinesPanelComponent {
  @Input() userUuid?: string;

  public readonly panelDataSource    = inject(SubscribedMachinesPanelDataSource);
  public readonly updating           = signal(false);
  public readonly loading            = computed(
    () => this.updating() || this.panelDataSource.loading(),
  );
  public nextPriority: number | null = null;

  public readonly data                       = toSignal(
    this.panelDataSource.connect({id: 'to-signal-subscribed-machines-panel'}),
  );
  private readonly updateSubscriptionsMethod = inject(
    NotificationAccordionSubscribedMachinesControllerUpdateSubscriptionsRemoteMethod,
  );

  protected readonly autocompleteLabel = $localize`Machine subscriber`;
  protected readonly autoCompletePlaceholder = $localize`Add a new machine subscriber to this priority group`;

  get currentUserUuid() {
    return this.userUuid ?? this.panelDataSource.data?.uuid;
  }

  async updateSubscriber(machineList: Array<PriorityItem<AutocompleteMethod>>) {
    this.updating.set(true);
    await this.updateSubscriptionsMethod.call({
      parameters: {
        uuid: this.currentUserUuid!,
      },
      requestBody: {
        uuid: this.currentUserUuid!,
        machineList: machineList,
      },
    });
    this.panelDataSource.refresh();
    this.updating.set(false);
  }
}
