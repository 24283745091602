import { inject, Injectable, signal } from '@angular/core';
import { TrackingService } from './tracking.service';

@Injectable({ providedIn: 'root' })
export class SideBySideService {
  private trackingService = inject(TrackingService);
  public readonly opened = signal(false);

  open() {
    this.opened.set(true);
    this.trackingService.trackEvent('open helper', { category: 'side-by-side' });
  }

  close() {
    this.opened.set(false);
    this.trackingService.trackEvent('close helper', { category: 'side-by-side' });
  }
}
