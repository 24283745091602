<h2 class="!m-0 !px-4" mat-dialog-title>{{ data.title ?? defaultTitle }}</h2>
<mat-dialog-content class="!p-0">

  <div class="p-4 max-h-[80vh] overflow-y-auto">
    <div>{{ data.message }}</div>

    <div class="my-4" i18n>Please confirm the changes by writing "<span (click)="copyToClipboard()" class="font-bold text-primary-500 cursor-pointer">{{data.confirmText}}</span>" in the field below.</div>

    <mat-form-field>
      <input matInput type="text" [(ngModel)]="confirmText" >
    </mat-form-field>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="true" [disabled]="confirmText !== data.confirmText" color="accent" mat-raised-button>
    {{ data.action ?? defaultAction }}
  </button>
  <button [mat-dialog-close]="false" i18n mat-button>Cancel</button>
</mat-dialog-actions>
