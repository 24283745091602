<div class="flex flex-row justify-between items-center">
  <h2 mat-dialog-title i18n>User settings</h2>
  <button class="mr-4" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="w-[950px]" mat-dialog-content>
  <ng-template #errorTemplate let-error let-refresh="refresh">
    <rxap-data-source-error [error]="error" [refresh]="refresh"></rxap-data-source-error>
  </ng-template>
  <div class="flex flex-col gap-y-4">
    @if (accordionDataSource.loading$ | async) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
    <mat-accordion *rxapDataSource="let data from accordionDataSource; errorTemplate: errorTemplate" [rxapPersistentAccordion]="data.uuid">

      <mat-expansion-panel expanded>

        <mat-expansion-panel-header>
          <mat-panel-title i18n>Account information</mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <mat-list>
            <mat-list-item><span i18n>Email</span>: {{ accordionDataSource.user?.username }}</mat-list-item>
            <mat-list-item><span i18n>First name</span>: {{accordionDataSource.user?.firstname }}</mat-list-item>
            <mat-list-item><span i18n>Last name</span>: {{accordionDataSource.user?.lastname }}</mat-list-item>
            <mat-list-item><span i18n>Role</span>: {{accordionDataSource.user?.role }}</mat-list-item>
            <mat-list-item><span i18n>Company</span>: {{accordionDataSource.user?.companyName }}</mat-list-item>
          </mat-list>

          <button (click)="openResetPasswordDialog()" color="primary" mat-raised-button>
            <span class="flex flex-row justify-start items-center gap-[8px]">
              <mat-icon class="grow-0" svgIcon="lock-reset"></mat-icon>
              <span class="grow-0" i18n>Reset password</span>
            </span>
          </button>
        </ng-template>

      </mat-expansion-panel>

      <mat-expansion-panel>

        <mat-expansion-panel-header>
          <mat-panel-title i18n>Notification settings</mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <eurogard-settings-panel></eurogard-settings-panel>
        </ng-template>

      </mat-expansion-panel>


      <mat-expansion-panel>

        <mat-expansion-panel-header>
          <mat-panel-title i18n>Subscribed Machines for notifications</mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <eurogard-subscribed-machines-panel [userUuid]="accordionDataSource.user.uuid"></eurogard-subscribed-machines-panel>
        </ng-template>

      </mat-expansion-panel>

    </mat-accordion>
  </div>

</div>
<div mat-dialog-actions>
  <button i18n mat-button mat-dialog-close>Close</button>
</div>
