import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfigService } from '@rxap/config';
import { Title } from '@angular/platform-browser';
import { KeycloakService } from '@rxap/keycloak';
import { StatusIndicatorComponent } from '@rxap/ngx-status-check';
import { LanguageRedirectService } from 'angular-services/language-redirect.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { EUROGARD_DATE_FORMATS } from 'angular-forms/date-format';

@Component({
  selector: 'eurogard-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ RouterOutlet, StatusIndicatorComponent ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: EUROGARD_DATE_FORMATS },
  ],
})
export class AppComponent {

  constructor(
    config: ConfigService<any>,
    titleService: Title,
    @Inject(KeycloakService)
    protected readonly keycloak: KeycloakService,
    private languageRedirectService: LanguageRedirectService,
  ) {
    this.languageRedirectService.redirectLanguageIfNeeded();
    if (config.get('app.name', null)) {
      titleService.setTitle(config.getOrThrow('app.name'));
    }
  }

}
