<h2 class="!m-0 !px-4" mat-dialog-title>{{ data.title ?? defaultTitle }}</h2>
<mat-dialog-content class="p-0">

  <div class="!p-4 max-h-[80vh] overflow-y-auto">
    <div>{{ data.message }}</div>

    @if (data.warning) {
      <div class="flex items-center text-yellow-500" [class.mt-4]="data.message !== undefined">
        <mat-icon>warning</mat-icon>
        <div>{{ data.warning }}</div>
      </div>
    }
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="true" color="accent" mat-raised-button>{{ data.action ?? defaultAction }}</button>
  <button [mat-dialog-close]="false" i18n mat-button>Cancel</button>
</mat-dialog-actions>
