<form #form="rxapForm" class="min-w-[380px]" rxapForm>

  <mat-form-field class="w-full">
    <mat-label>{{ label() }}</mat-label>
    <button mat-icon-button matSuffix rxapInputClearButton tabindex="-1" type="button">
      <mat-icon>clear</mat-icon>
    </button>
    <input
      [matAutocomplete]="autoItem"
      [placeholder]="placeholder()"
      autocomplete="off"
      formControlName="item"
      matInput
      rxapRequired
      type="text"
    />
    <mat-autocomplete #autoItem="matAutocomplete" (optionSelected)="optionSelected($event)">
      <mat-option
        *rxapAutocompleteOptionsFromMethod="let option; matAutocomplete: autoItem"
        [value]="option.value"
      >
        {{ option.display }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *rxapControlError="let erro; key: 'required'">
      {{ label() }}
      <ng-container i18n>is required</ng-container>
    </mat-error>
    <mat-error *rxapControlError="let erro; key: 'autocomplete'">
      {{ label() }}
      <ng-container i18n>is not valid</ng-container>
    </mat-error>
    <mat-hint>
      <ng-container i18n>Search for a</ng-container>
      {{ label() }}
    </mat-hint>
  </mat-form-field>

</form>
