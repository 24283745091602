import { ChangeDetectionStrategy, Component, inject, effect, input, output } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import {
  ControlErrorDirective,
  InputClearButtonDirective,
  RequiredDirective,
} from '@rxap/material-form-system';
import { RXAP_FORM_DEFINITION, RxapFormsModule } from '@rxap/forms';
import { FormComponentProviders, FormProviders } from './form.providers';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AutocompleteOptionsFormMethodDirective } from 'autocomplete-table-select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { AutocompleteItem } from './types';
import { AutocompleteForm, IAutocompleteForm } from './autocomplete.form';

@Component({
  standalone: true,
  selector: 'eurogard-autocomplete-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './autocomplete-form.component.html',
  styleUrls: [ './autocomplete-form.component.scss' ],
  imports: [
    ReactiveFormsModule,
    RxapFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    AutocompleteOptionsFormMethodDirective,
    MatIconModule,
    MatButtonModule,
    InputClearButtonDirective,
    RequiredDirective,
    ControlErrorDirective,
  ],
  providers: [
    FormProviders,
    FormComponentProviders,
    {
      provide: MatFormField,
      useValue: null,
    },
    {
      provide: NgControl,
      useValue: null,
    },
  ],
})
export class AutocompleteFormComponent {

  label = input($localize`Name`);
  placeholder = input($localize`Enter a name`);
  disabled = input(false);

  item = output<AutocompleteItem>();

  submit = output<IAutocompleteForm>();

  form = inject<AutocompleteForm>(RXAP_FORM_DEFINITION);

  constructor() {
    this.submit.subscribe(value => {
      this.item.emit(value.item);
    });
    effect(() => {
      if (this.disabled()) {
        this.form.rxapFormGroup.disable();
      } else {
        this.form.rxapFormGroup.enable();
      }
    });
  }

  optionSelected(event: MatAutocompleteSelectedEvent) {
    this.submit.emit({ item: { ...event.option.value, display:event.option.viewValue } });
    this.form.rxapFormGroup.reset();
  }

}
