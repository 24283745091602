import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { Pipe, PipeTransform, ViewContainerRef } from '@angular/core';
import { HelperPage } from 'angular-components/helper/helper';

@Pipe({
  name: 'helperPagePortal',
  standalone: true,
})
export class HelperPagePortalPipe implements PipeTransform {
  constructor(private viewContainerRef: ViewContainerRef) {}

  transform(value: HelperPage): Portal<any> | null {
    return new ComponentPortal(
      value.component,
      this.viewContainerRef,
    );
  }
}
