import { Injectable } from '@angular/core';
import { Angulartics2Matomo } from 'angulartics2';

interface EventProperties {
  category: string;
  name?: string;
  label?: string;
  value?: number;
}

@Injectable()
export class TrackingService {
  constructor(
    private angulartics: Angulartics2Matomo,
  ) {
    angulartics.startTracking();
  }

  public trackEvent(action: string, properties: EventProperties) {
    this.angulartics.eventTrack(action, { value: undefined, ...properties });
  }
}
